import axios from "axios"

export const saveCart = (cart, cartTotal) => {
  localStorage.setItem("cart_local", JSON.stringify(cart))
}

export const getCart = () => {
  try {
    const cart = JSON.parse(localStorage.getItem("cart_local"))
    if (cart) {
      return cart
    }
    return []
  } catch (err) {
    return []
  }
}

export const getCartTotal = (cart) => {
  if (!cart || cart.length === 0) return 0

  const newTotal = cart.reduce((counter, item) => {
    return counter + item.qty
  }, 0)
  return newTotal
}

// Math Functions //
export const priceInCents = (price) => {
  return price.toFixed(2) * 100
}

export const priceInDollar = (price) => {
  return price.toFixed(2) / 100
}
/**
 * Get the cart subtotal before taxes and shipping costs.
 * @param {NUMBER} cart
 * @return {NUMBER} cart sub-total
 */
export const cartSubtotal = (cart) => {
  const subTotal = cart.reduce((counter, product) => {
    return counter + product.price_in_cents * parseInt(product.qty)
  }, 0)

  return parseInt(subTotal)
}
/**
 * Get the current taxes based on the tax rate based on user location.
 * @param {NUMBER} subTotal
 * @param {NUMBER} taxRate
 * @return {NUMBER}
 */
export const cartTotalTax = (subTotal, taxRate, cardDiscount = 0) => {
  const subTotalAfterDiscount =
    subTotal - cardDiscount <= 0 ? 0 : subTotal - cardDiscount
  const taxes = Math.floor(subTotalAfterDiscount * taxRate)
  return taxes
}
/**
 *
 * @param {ARRAY} cart
 * @param {NUMBER} shippRate
 * @param {NUMBER} taxRate
 * @return {NUMBER} cart total in user state.
 */
export const cartTotal = (cart, shippRate, taxRate, giftCard = 0) => {
  const subTotal =
    cartSubtotal(cart) - giftCard >= 0 ? cartSubtotal(cart) - giftCard : 0
  const shipping = shippRate
  const taxes = cartTotalTax(subTotal, taxRate)
  const total = subTotal + taxes + shipping
  return Math.round(total)
}

export const confirmTotal = async (
  cart,
  uiDispatch,
  location,
  province,
  giftCardId
) => {
  uiDispatch({ type: "SERVER_LOAD" })
  try {
    const reponse = await axios.post(
      `${process.env.GATSBY_API_URL}/orders/confirm`,
      { cart, location, province, giftCardId }
    )

    uiDispatch({ type: "SERVER_LOAD_FINISHED" })

    return reponse.data.cartTotal
  } catch (err) {
    uiDispatch({ type: "SERVER_LOAD_FINISHED" })
    uiDispatch({ type: "ALERT_ERROR", payload: err.response.data.message })
    return null
  }
}
