// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-checkout-cancel-js": () => import("./../../../src/pages/checkout/cancel.js" /* webpackChunkName: "component---src-pages-checkout-cancel-js" */),
  "component---src-pages-checkout-success-js": () => import("./../../../src/pages/checkout/success.js" /* webpackChunkName: "component---src-pages-checkout-success-js" */),
  "component---src-pages-disclaimer-js": () => import("./../../../src/pages/disclaimer.js" /* webpackChunkName: "component---src-pages-disclaimer-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-shop-cart-js": () => import("./../../../src/pages/shop/cart.js" /* webpackChunkName: "component---src-pages-shop-cart-js" */),
  "component---src-pages-shop-checkout-js": () => import("./../../../src/pages/shop/checkout.js" /* webpackChunkName: "component---src-pages-shop-checkout-js" */),
  "component---src-pages-shop-js": () => import("./../../../src/pages/shop.js" /* webpackChunkName: "component---src-pages-shop-js" */),
  "component---src-pages-shop-policies-js": () => import("./../../../src/pages/shop-policies.js" /* webpackChunkName: "component---src-pages-shop-policies-js" */),
  "component---src-templates-about-js": () => import("./../../../src/templates/about.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-adventure-js": () => import("./../../../src/templates/adventure.js" /* webpackChunkName: "component---src-templates-adventure-js" */),
  "component---src-templates-adventures-js": () => import("./../../../src/templates/adventures.js" /* webpackChunkName: "component---src-templates-adventures-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-flybox-js": () => import("./../../../src/templates/flybox.js" /* webpackChunkName: "component---src-templates-flybox-js" */),
  "component---src-templates-instagram-js": () => import("./../../../src/templates/instagram.js" /* webpackChunkName: "component---src-templates-instagram-js" */),
  "component---src-templates-media-js": () => import("./../../../src/templates/media.js" /* webpackChunkName: "component---src-templates-media-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */)
}

