import React, { createContext, useReducer } from "react"

export const RatesContext = createContext()

const initialState = {
  activeLocation: "canada",
  activeProvince: "alberta",
  activeShipping: 1400,
  activeTax: 0.0,
  freeShipping: false,
  giftCard: 0,
  giftCardId: "",
}

const reducer = (state, action) => {
  switch (action.type) {
    case "LOCATION_CHANGE":
      return {
        ...state,
        activeLocation: action.payload.activeLocation,
        // activeShipping: action.payload.activeShipping,
        // activeTax: action.payload.activeTax,
      }
    case "PROVINCE_CHANGE":
      return {
        ...state,
        activeProvince: action.payload.activeProvince,
        // activeShipping: action.payload.activeShipping,
        // activeTax: action.payload.activeTax,
      }
    case "RATE_CHANGE":
      return {
        ...state,
        activeShipping: action.payload.activeShipping,
        freeShipping: action.payload.freeShipping,
      }
    case "GIFTCARD_CHANGE":
      return {
        ...state,
        giftCard: action.payload.balance,
        giftCardId: action.payload.cardID,
      }

    default:
      return state
  }
}

export const RatesContextProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <RatesContext.Provider value={[state, dispatch]}>
      {props.children}
    </RatesContext.Provider>
  )
}
