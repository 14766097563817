import _ from "lodash"
export const doesVariantsMatch = (prosInCart, varPicked) => {
  const matchedCartProduct = prosInCart.filter(cartPro => {
    const matchFound = _.isEqual(
      cartPro.variants_picked.sort(),
      varPicked.sort()
    )
    return matchFound
  })
  return matchedCartProduct
}
