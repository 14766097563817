import React, { createContext, useReducer } from "react"

export const UiContext = createContext()

const initialState = {
  loadingActive: false,
  alertActive: false,
  error: false,
  message: "",
}

const reducer = (state, action) => {
  switch (action.type) {
    case "SERVER_LOAD":
      return {
        ...state,
        loadingActive: true,
      }

    case "SERVER_LOAD_FINISHED":
      return {
        ...state,
        loadingActive: false,
      }

    case "ALERT_CLEAR":
      return {
        ...state,
        alertActive: false,
        message: "",
        error: false,
      }
    case "ALERT_SUCCESS":
      return {
        ...state,
        alertActive: true,
        message: action.payload,
      }
    case "ALERT_ERROR":
      return {
        ...state,
        alertActive: true,
        message: action.payload,
        error: true,
      }
    default:
      return state
  }
}

export const UiContextProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  return (
    <UiContext.Provider value={{ state, dispatch }}>
      {props.children}
    </UiContext.Provider>
  )
}
