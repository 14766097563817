import React from "react"
import LogRocket from "logrocket"

import CartContextProvider from "./src/context/CartContext"
import { UiContextProvider } from "./src/context/UiContext"
import { RatesContextProvider } from "./src/context/RatesContext"

export const wrapRootElement = ({ element }) => {
  return (
    <UiContextProvider>
      <RatesContextProvider>
        <CartContextProvider>{element}</CartContextProvider>
      </RatesContextProvider>
    </UiContextProvider>
  )
}

export const onInitialClientRender = () => {
  if (typeof window !== "undefined" && process.env.NODE_ENV === "production") {
    LogRocket.init("vru129/drift-out-west", {
      dom: {
        textSanitizer: true,
        inputSanitizer: true,
      },
    })
  }
}
