import React, { useState, createContext } from "react"
import { v4 as uuidv4 } from "uuid"

import { getCart, saveCart, getCartTotal } from "../Utilities/cart/cart"
import { doesVariantsMatch } from "../Utilities/variants/variants"

export const CartContext = createContext(null)

export default ({ children }) => {
  const [cart, setCart] = useState(getCart())
  const [cartTotal, setCartTotal] = useState(getCartTotal(cart))

  const clearCart = () => {
    const updatedCart = []
    const cartTotal = 0
    saveCart(updatedCart, cartTotal)
    setCart(updatedCart)
    setCartTotal(cartTotal)
  }

  const updateCart = updatedCart => {
    setCart(updatedCart)
    saveCart(updatedCart)
  }

  const updateCartTotal = copy => {
    const newTotal = copy.reduce((counter, item) => {
      return counter + item.qty
    }, 0)
    setCartTotal(newTotal)
    updateCart(copy)
  }

  const changeCartQty = (product, type) => {
    const copy = [...cart]

    const indexOfProduct = cart.findIndex(
      cartPro => cartPro.cart_id === product.cart_id
    )

    if (type === "add") {
      copy[indexOfProduct].qty = copy[indexOfProduct].qty + 1
    } else if (type === "subtract") {
      if (product.qty <= 1) {
        copy.splice(indexOfProduct, 1)
      } else {
        copy[indexOfProduct].qty = copy[indexOfProduct].qty - 1
      }
    } else if (type === "delete") {
      copy.splice(indexOfProduct, 1)
    }
    updateCartTotal([...copy])
  }

  const addToCart = (product, qty = 1, variants = []) => {
    // make a copy of the local state cart. //
    const copy = [...cart]
    // Add pricing in cents. //
    product.price_in_cents = product.price * 100
    // if the cart is empty no need to check, just create an new product. //
    if (copy.length <= 0) {
      product.qty = parseInt(qty)
      product.variants_picked = [...variants]
      product.cart_id = uuidv4()
      const updatedCart = [...copy, product]
      updateCartTotal(updatedCart)
      return
    }

    // Check to see if any exisitng cart items have the same id as the product trying to be added to cart. //
    const proIdMatch = copy.filter(proInCart => {
      return proInCart.id === product.id
    })
    // Nothing in the cart matches with the item trying to be add, therefore it must be a new item. //
    if (proIdMatch.length <= 0) {
      product.qty = parseInt(qty)
      product.variants_picked = [...variants]
      product.cart_id = uuidv4()
      const updatedCart = [...copy, product]
      updateCartTotal(updatedCart)
      return
    }
    // Now we must check and see if these 2 identical items have any varients.
    let sameProductInCartNoVariants = false
    let indexOfTheSameProducts = -1
    proIdMatch.forEach(proMatch => {
      if (
        proIdMatch.length === 1 &&
        proMatch.variants_picked.length <= 0 &&
        variants.length <= 0
      ) {
        sameProductInCartNoVariants = true
        indexOfTheSameProducts = copy.findIndex(proInCart => {
          return proInCart.id === product.id
        })
      }
    })
    // The both have no variants required now we need to update the totals. //
    if (sameProductInCartNoVariants && indexOfTheSameProducts !== -1) {
      copy[indexOfTheSameProducts].qty += parseInt(qty)
      updateCartTotal(copy)
      return
    }

    // Okay if we;ve made it this far, the ids match and the product have required variant options.//
    // We need to see if any of these options selected match, for example medium red t-shirt, if match just update the total rather than a new item in the cart..
    const doVarsMatch = doesVariantsMatch(proIdMatch, variants)
    // If the function returns an empty array we know that they are the same product but different selected variants. //
    // Therefore we need to create a new product in the local cart. //
    if (doVarsMatch.length <= 0) {
      product.qty = parseInt(qty)
      product.variants_picked = [...variants]
      product.cart_id = uuidv4()
      const updatedCart = [...copy, product]
      updateCartTotal(updatedCart)
      return
    }

    // We have a match product for id and variants, just update the qty. //
    // first we need the index of the matched item in the local cart. //
    const indexOfMatched = copy.findIndex(
      proInCart => proInCart.cart_id === doVarsMatch[0].cart_id
    )
    // Now we can update the total of that item with the added amount. //
    copy[indexOfMatched].qty += parseInt(qty)
    updateCartTotal(copy)
    return
  }

  return (
    <CartContext.Provider
      value={{
        cart,
        cartTotal,
        updateCart,
        addToCart,
        clearCart,
        changeCartQty,
        setCartTotal,
      }}
    >
      {children}
    </CartContext.Provider>
  )
}
